<template>
  <div>
    <s-header :name="titleName"></s-header>
    <van-address-edit
        :area-list="areaList"
        :address-info="addressInfo"
        show-set-default
        :search-result="searchResult"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
    />
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRefs} from 'vue';
import sHeader from '@/components/SimpleHeader'
import {areaList} from '@vant/area-data';
import {useRouter} from "vue-router";
import {getAddress1,handleAddressCreate,handleAddressCreate1} from "../../../service/product";

export default {
  name: "addSite",
  components: {
    sHeader
  },
  setup() {
    const searchResult = ref([]);
    const router = useRouter()
    const state = reactive({
      manageType: '',
      titleName: '新增收货地址',
      addressData: [],
      addressInfo: {}
    })
    const onSave = async (e) => {
      if (router.currentRoute.value.query.type === 'edit') {
        const {data} = await handleAddressCreate1({
          realname:e.name,
          mobile: e.tel,
          address_details: e.addressDetail,
          is_default: e.isDefault ? 1 : 0,
          province_id: e.province,
          city_id: e.city,
          area_id: e.county,
          area_code: e.areaCode
        }, router.currentRoute.value.query.id)
        if(data){
          router.back()
        }
      }else {
        const {data} = await handleAddressCreate({
          realname:e.name,
          mobile: e.tel,
          address_details: e.addressDetail,
          is_default: e.isDefault ? 1 : 0,
          province_id: e.province,
          city_id: e.city,
          area_id: e.county,
          area_code: e.areaCode
        })
        if(data){
          router.back()
        }
      }
    };
    const onDelete = () => console.log('delete');
    onMounted(async () => {
      initData()
    })
    // 数据初始化
    const initData = async () => {
      if (router.currentRoute.value.query.type === 'edit') {
        state.titleName = '编辑收货地址'
        await getAddressDetail(router.currentRoute.value.query.id)
      }
      state.manageType = router.currentRoute.value.query.type;
    }
    // 获取收货地址
    const getAddressDetail = async id => {
      const {data} = await getAddress1(id)
      state.addressData = data;
      if (router.currentRoute.value.query.type === 'edit') {
        state.addressInfo = {
          name: data.realname,
          tel: data.mobile,
          addressDetail: data.address_details,
          areaCode: data.area_code,
          isDefault: data.is_default == 1 ? true : false
        }
      }
    }
    return {
      onSave,
      onDelete,
      areaList,
      searchResult,
      initData,
      ...toRefs(state)
    }
  },
}
</script>

<style scoped>

</style>
